<template>
    <ol class="breadcrumb">
        <li v-for="item in this.getCrumbs" v-bind:key="item.id" class="breadcrumb-item">
            <router-link :to="`/${item.slug}/${item.id}`">
                {{ item.label }}
            </router-link>
        </li>
    </ol>
</template>

<script>
    export default {
        name: "BreadCrumbs",
        props: ['breadCrumbs'],
        computed: {
            getCrumbs: function () {
                let filteredCrumbs = [];

                for (let i = 0; i < this.breadCrumbs.length; i++) {
                    if (this.breadCrumbs[i].id && this.breadCrumbs[i].label) {
                        filteredCrumbs.push(this.breadCrumbs[i]);
                    }
                }

                return filteredCrumbs;
            }
        }
    }
</script>

<style scoped>

</style>