<template>
    <div>
        <div class="row">
            <div class="col-sm-12 col-md-6 dashboard_md_text hide_overflow">
                <div>
                    <a class="cdi_link" :href="`mailto:${data.email}`">
                        <span class="glyphicon glyphicon-envelope"></span>
                        {{ data.email }}
                    </a>
                </div>
                <div v-show="data.phone">
                    <a class="cdi_link" :href="`tel:${data.phone}`">
                        <span class="glyphicon glyphicon-phone"></span>
                        {{ data.phone }}
                    </a>
                </div>
                <div>
                    <a target="_blank" class="cdi_link" id="location_link" :href="`https://www.google.com/maps/search/?api=1&query=${locationQueryString}`">
                        <span class="glyphicon glyphicon-map-marker"></span>
                        {{ `${data.city}, ${data.state}` }}
                    </a>
                </div>
                <div class='hide_overflow margin_bottom'>
                    {{ data.job_title }}
                    <span v-if="data.primary_branch_id !== 0">
                         @ <router-link :to="`/branch/${data.primary_branch_id}`">
                            {{ data.branch_name }}
                        </router-link>
                    </span>
                </div>
                <div class="row ">
                    <div class="col-xs-4">
                        <label>Sales</label>
                    </div>
                    <div class="col-xs-4 number">
                        <router-link :to="{ path: '/rpt/sales', query: { actual_ship_start: startOfYear, actual_ship_end: today, distributor_search: `${data.first_name} ${data.last_name}`, distributor_search_id: data.user_id }}" class="cdi_link">
                            ${{ parseInt(data.sales_ytd).toLocaleString('en-US') }}
                        </router-link>
                    </div>
                    <div v-show="data.sales_diff" :class="[parseInt(data.sales_ytd_last_year) > parseInt(data.sales_ytd) ? 'text-danger' : 'text-success', 'col-xs-4 number']">
                        {{ data.sales_diff }}%
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-4">
                        <label>Quotes</label>
                    </div>
                    <div class="col-xs-4 number">
                        <router-link :to="{ path: '/rpt/quote_rpt', query: { quote_start: startOfYear, quote_end: today, distributor_search: `${data.first_name} ${data.last_name}`, distributor_search_id: data.user_id  }}" class="cdi_link">
                            ${{ parseInt(data.quotes_ytd).toLocaleString('en-US') }}
                        </router-link>
                    </div>
                    <div v-show="data.quotes_diff" :class="[parseInt(data.quotes_ytd_last_year) > parseInt(data.quotes_ytd) ? 'text-danger' : 'text-success', 'col-xs-4 number']">
                        {{ data.quotes_diff }}%
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-md-6">
                <div class="chart_container dashboard_chart_container">
                    <Chart v-bind:results="chartData"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Chart from "@/components/utils/Chart";
    import DateHelpers from "@/store/DateHelpers";

    export default {
        name: "Dashboard",
        components: {
            Chart
        },
        props: ['data'],
        data() {
            return {
                startOfYear: DateHelpers.getFirstOfTheYear(),
                today: DateHelpers.getToday()
            }
        },
        computed: {
            chartData: function () {
                return {chartData: this.data.chart};
            },
            locationQueryString: function () {
                return encodeURIComponent(`${this.data.address} ${this.data.city} ${this.data.state}`);
            }
        },
    }
</script>

<style scoped>

</style>