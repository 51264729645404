<template>
    <div>
        <div class="panel panel-default">
            <div class="row panel-body details_container">
                <div class="col-lg-4 col-sm-6 col-xs-12">
                    <DetailsText ref="password" :field="{name: 'passwd', label: 'Password'}" @save="savePassword" />
                </div>
                <div class="col-lg-4 col-sm-6 col-xs-12">
                    <DetailsSelect :field="{name: 'title', label: 'Title', options: getTitles }"
                                   :val="data.title"
                                   @save="employee"
                    />
                </div>
                <div class="col-lg-4 col-sm-6 col-xs-12">
                    <DetailsSelect :field="{name: 'user_staff_shift_id', label: 'Shift', options: this.cache.shiftCache.SHIFTS }"
                                   :val="data.user_staff_shift_id"
                                   @save="employee"
                    />
                </div>
                <div class="col-lg-4 col-sm-6 col-xs-12">
                    <DetailsText :field="{name: 'rate', label: 'Pay Rate'}" :val="data.rate" @save="employee" />
                </div>
                <div class="col-lg-4 col-sm-6 col-xs-12">
                    <DetailsCheckbox :field="{name: 'is_sales_person', label: 'Is Salesperson'}" :val="data.is_sales_person" @save="employee" />
                </div>
                <div class="col-lg-4 col-sm-6 col-xs-12">
                    <DetailsSelect :field="{name: 'dashboard_id', label: 'Dashboard', options: this.cache.dashboardCache.TYPES }"
                                   :val="data.dashboard_id"
                                   @save="employee"
                    />
                </div>
                <div class="col-lg-4 col-sm-6 col-xs-12">
                    <DetailsTypeahead :field="{
                                           name: 'supervisor_id',
                                           label: 'Supervisor',
                                           cdiType: 'employee',
                                           display: data.supervisor,
                                           link: 'user'
                                       }"
                                      :val="data.supervisor_id"
                                      @save="employee"
                    />
                </div>
                <div class="col-lg-4 col-sm-6 col-xs-12">
                    <DetailsText :field="{name: 'emergency_name', label: 'Emergency Contact'}" :val="data.emergency_name" @save="employee" />
                </div>
                <div class="col-lg-4 col-sm-6 col-xs-12">
                    <DetailsText :field="{name: 'emergency_phone', label: 'Emergency Phone'}" :val="data.emergency_phone" @save="employee" />
                </div>
                <div class="col-lg-4 col-sm-6 col-xs-12">
                    <DetailsCheckbox :field="{name: 'has_gapps', label: 'GApps Account'}" :val="data.has_gapps" @save="employee" />
                </div>
                <div class="col-lg-4 col-sm-6 col-xs-12">
                    <DetailsSelect :field="{name: 'department', label: 'Department', options: this.cache.userCache.DEPARTMENTS}" :val="data.department" @save="employee" />
                </div>
                <div class="col-lg-4 col-sm-6 col-xs-12">
                    <DetailsCheckbox :field="{name: 'off_prem_clock', label: 'Allow off-premises clock-in/out'}" :val="data.off_prem_clock" @save="employee" />
                </div>
                <div class="col-lg-4 col-sm-6 col-xs-12">
                    <DetailsSelect :field="{name: 'auto_drawing_user_team', label: 'Driveworks Team', options: this.cache.userCache.AUTO_DRAWING_TEAMS}" :val="data.auto_drawing_user_team" @save="employee" />
                </div>
                <div class="col-lg-4 col-sm-6 col-xs-12">
                    <div class="object_detail">
                        <label class="object_detail_select">Google App Token</label>
                        <button class="form-control" @click="showConfirm = true">
                            Clear Token
                        </button>
                    </div>
                </div>
            </div>

            <ConfirmBox v-if="showConfirm"
                        @close="clearToken"
                        heading="Reset Google App Token?"
                        body="Once you have cleared the existing Google token, the account will need to be logged out and back in to complete the reset."
            />
        </div>

        <div class="spacing_top">
            <img v-if="data.photo_file_id" :src="data.presigned_img_url" style='width:180px;'>
        </div>

        <h3>Roles</h3>
        <div>
            <table class="table">
                <thead>
                    <tr>
                        <th>Role</th>
                        <th>Yes</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Super</td>
                        <td>
                            <input type="checkbox" disabled :checked="hasRole(cdiVars.CDI_ROLE_SUPER)" />
                        </td>
                    </tr>
                    <tr v-for="[id, value] in allRoles" v-bind:key="id">
                        <td>{{ value }}</td>
                        <td>
                            <input type="checkbox" :id="value" :checked="hasRole(id)" @change="updateRole(id)" />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import { store } from "@/store/BusinessObject.store";
    import { cdiVars } from "@/cdiVars";
    import titleCache from "@/cache/title.cache";
    import shiftCache from "@/cache/shift.cache";
    import userCache from "@/cache/user.cache";
    import dashboardCache from "@/cache/dashboard.cache";
    import rolesCache from "@/cache/roles.cache";
    import DetailsText from "@/components/businessObjects/utils/DetailsText";
    import DetailsSelect from "@/components/businessObjects/utils/DetailsSelect";
    import DetailsCheckbox from "@/components/businessObjects/utils/DetailsCheckbox";
    import DetailsTypeahead from "@/components/businessObjects/utils/DetailsTypeahead";
    import ConfirmBox from "@/components/utils/ConfirmBox";

    export default {
        name: "CDI",
        props: ["data"],
        data() {
            return {
                cdiVars: cdiVars,
                cache: {
                    titleCache,
                    shiftCache,
                    dashboardCache,
                    rolesCache,
                    userCache
                },
                showConfirm: false
            }
        },
        components: {
            ConfirmBox,
            DetailsTypeahead,
            DetailsCheckbox,
            DetailsSelect,
            DetailsText
        },
        methods: {
            savePassword: function (data) {
                let field = `params[${data.field}]`;
                let options = {[field]: data.val};
                this.$refs.password.$data.localVal = null;
                store.apiWithObjectLoad('save_cdi_passwd', options);
            },
            employee: function (data) {
                let field = `params[${data.field}]`;
                let options = {[field]: data.val};
                store.apiWithObjectLoad('save_employee', options);
            },
            clearToken: function (confirm) {
                this.showConfirm = false;

                if (!confirm) {
                    return;
                }

                store.api('clear_token');
            },
            updateRole: function (roleID) {
                let options = {
                    'params[cdi_role_id]': roleID,
                    'params[cdi_role]': Number(!this.data.roles.includes(roleID))
                };

                store.apiWithObjectLoad('save_cdi_role', options);
            },
            hasRole: function (roleID) {
                if (!this.data.roles || !this.data.roles.length) {
                    return false;
                }

                return this.data.roles.includes(roleID);
            }
        },
        computed: {
            getTitles: function () {
                let titles = [];
                this.cache.titleCache.TITLES.forEach(row => titles.push([row, row]));

                return titles;
            },
            imgServer: function () {
                return process.env.VUE_APP_URL;
            },
            allRoles: function () {
                // Alphabetical sort & strip super user role
                return rolesCache.ALL.sort(function (a, b) {
                    if (a[1] < b[1]) {
                        return -1;
                    }

                    if (a[1] > b[1]) {
                        return 1;
                    }

                    return 0;
                }).filter(role => role[0] !== cdiVars.CDI_ROLE_SUPER);
            }
        }
    }
</script>

<style scoped>

</style>