<template>
    <div>
        <h3>Contact</h3>

        <div class="panel panel-default">
            <div class="row panel-body details_container">
                <div class="col-lg-4 col-sm-6 col-xs-12">
                    <DetailsText :field="{name: 'address', label: 'Address'}"
                                 :val="data.address"
                                 @save="saveAddress"
                    />
                </div>
                <div class="col-lg-4 col-sm-6 col-xs-12">
                    <DetailsTypeahead :field="{name: 'city', label: 'City', cdiType: 'city', display: data.city}"
                                      :val="data.city"
                                      @save="saveAddress"
                    />
                </div>
                <div class="col-lg-4 col-sm-6 col-xs-12">
                    <DetailsSelect :field="{name: 'state', label: 'State', options: cache.stateCache.STATES}"
                                   :val="data.state"
                                   @save="saveAddress"
                    />
                </div>
                <div class="col-lg-4 col-sm-6 col-xs-12">
                    <DetailsText :field="{name: 'zip', label: 'Zip', reset: true}"
                                 :val="data.zip"
                                 @save="saveAddress"
                    />
                </div>
                <div class="col-lg-4 col-sm-6 col-xs-12">
                    <DetailsText :field="{name: 'phone', label: 'Phone'}"
                                 :val="data.phone"
                                 @save="standard"
                    />
                </div>
                <div class="col-lg-4 col-sm-6 col-xs-12" v-show="!isEmployee">
                    <DetailsText :field="{name: 'fax', label: 'Fax'}"
                                 :val="data.fax"
                                 @save="sales"
                    />
                </div>
                <div class="col-lg-4 col-sm-6 col-xs-12">
                    <DetailsText :field="{name: 'mphone', label: 'Mobile Phone'}"
                                 :val="data.mphone"
                                 @save="standard"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { store } from "@/store/BusinessObject.store";
    import stateCache from "@/cache/state.cache";
    import DetailsText from "@/components/businessObjects/utils/DetailsText";
    import DetailsSelect from "@/components/businessObjects/utils/DetailsSelect";
    import { cdiVars } from "@/cdiVars";
    import DetailsTypeahead from "@/components/businessObjects/utils/DetailsTypeahead";

    export default {
        name: "ContactDetails",
        components: {
            DetailsTypeahead,
            DetailsSelect,
            DetailsText},
        props: ['data'],
        data() {
            return {
                cache: {
                    stateCache
                }
            }
        },
        methods: {
            standard: function (data) {
                store.save(data.field, data.val);
            },
            saveAddress: function (data) {
                let field = `params[${data.field}]`;
                let options = { [field]: data.val };

                // Check if the field being saved is "zip"
                if (data.field === 'zip') {
                    // Include the state along with the zip in the options object
                    options['params[state]'] = this.data.state;
                }

                store.apiWithObjectLoad('save_primary_address', options);
            },
            sales: function (data) {
                let field = `params[${data.field}]`;
                let options = {[field]: data.val};
                store.apiWithObjectLoad('save_user_sales', options);
            }
        },
        computed: {
            isEmployee: function () {
                return this.data.user_type_id === cdiVars.USER_TYPE_EMPLOYEE;
            }
        }
    }
</script>

<style scoped>

</style>