export default {
	// Hard-coded list of cdi_lookup_type = 10
	TITLES: [
		'Administrative Support Specialist',
		'Applied Designer Drafter',
		'Applied Inside Sales Eng',
		'Batching Tech',
		'Billing/AR/CC Specialist',
		'Continuous Improvement Manager',
		'Controller',
		'Crater',
		'Design Engineer',
		'Design/Mrkting Specialist',
		'Designer Drafter',
		'Director of Production',
		'Drafter',
		'Engineer',
		'Engineering Manager',
		'Floater',
		'HR Generalist',
		'HR Manager',
		'Human Resources',
		'Human Resources/Administrative Assistant',
		'Inside Estimator',
		'Inside Sales',
		'Insulating/Crating Lead',
		'Insulator',
		'Integrator/COO',
		'Iso Tech',
		'IT Help Desk',
		'IT Infrastructure Admin',
		'Logistics Manager',
		'Logistics Support',
		'Maintenance Technician',
		'Material Handler',
		'Material Manager',
		'Measure Tech',
		'National Sales Rep',
		'New Prod Dev Eng',
		'Open',
		'Outside Sales',
		'Plant Manager',
		'Production Ops Manager',
		'Production Supervisor',
		'Purchasing/Facilities Manager',
		'QC Tech',
		'Quality Manager',
		'Regional Sales Rep',
		'Sales Support Lead',
		'Sales Support Rep',
		'Senior Recruiter',
		'Staff Accountant',
		'Turret Lead',
		'Turret Operator',
		'Visionary/CEO',
		'VP of Finance',
		'VP of Operations',
		'VP of Sales',
		'Web/IT Manager',
		'Welder',
		'Welder Lead'
	]
}