var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h3',[_vm._v("Other Settings")]),_c('div',{staticClass:"panel panel-default"},[_c('div',{staticClass:"row panel-body details_container"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isEmployee),expression:"!isEmployee"}],staticClass:"col-lg-4 col-sm-6 col-xs-12"},[_c('DetailsSelect',{attrs:{"field":{name: 'brand_id', label: 'Brand', options: _vm.cache.brandCache.BRANDS},"val":_vm.data.brand_id},on:{"save":_vm.sales}})],1),_c('div',{staticClass:"col-lg-4 col-sm-6 col-xs-12"},[_c('DetailsSelect',{attrs:{"field":{name: 'branch_id', label: 'Special Tag', options: _vm.cache.userCache.USER_SPECIAL_TAGS},"val":_vm.data.branch_id},on:{"save":_vm.standard}})],1),_c('div',{staticClass:"col-lg-4 col-sm-6 col-xs-12"},[_c('DetailsTypeahead',{attrs:{"field":{
                                        name: 'sales_user_id',
                                        label: 'Regional Sales Rep',
                                        display: this.data['sales_user'],
                                        cdiType: 'employee',
                                        link: 'user'
                                      },"val":_vm.data.sales_user_id},on:{"save":_vm.standard}})],1),_c('div',{staticClass:"col-lg-4 col-sm-6 col-xs-12"},[_c('DetailsTypeahead',{attrs:{"field":{
                                        name: 'inside_sales_user_id',
                                        label: 'Sales Support Rep',
                                        display: this.data['inside_sales_user'],
                                        cdiType: 'employee',
                                        link: 'user'
                                      },"val":_vm.data.inside_sales_user_id},on:{"save":_vm.standard}})],1),_c('div',{staticClass:"col-lg-4 col-sm-6 col-xs-12"},[_c('DetailsSelect',{attrs:{"field":{name: 'national_sales_team_id', label: 'National Sales Team', options: _vm.nationalSalesTeams},"val":_vm.data.national_sales_team_id},on:{"save":_vm.standard}})],1),_c('div',{staticClass:"col-lg-4 col-sm-6 col-xs-12"},[_c('DetailsTypeahead',{attrs:{"field":{
                                        name: 'user_shipping_co_id',
                                        label: 'Default Shipper',
                                        display: this.data['user_shipping_co'],
                                        cdiType: 'shipping_co',
                                        link: 'shipper'
                                      },"val":_vm.data.user_shipping_co_id},on:{"save":_vm.website}})],1),_c('div',{staticClass:"col-lg-4 col-sm-6 col-xs-12"},[_c('DetailsText',{attrs:{"field":{name: 'user_notes', label: 'Customer Notes'},"val":_vm.data.user_notes},on:{"save":_vm.standard}})],1),_c('div',{staticClass:"col-lg-4 col-sm-6 col-xs-12"},[_c('DetailsStatic',{attrs:{"field":{name: 'gapp_contact_id', label: 'GApp Contact'},"val":_vm.data.gapp_contact_id}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isEmployee),expression:"!isEmployee"}],staticClass:"col-lg-4 col-sm-6 col-xs-12"},[_c('DetailsCheckbox',{attrs:{"field":{name: 'targeted', label: 'Targeted Customer'},"val":_vm.data.targeted},on:{"save":_vm.sales}})],1),_c('div',{staticClass:"col-lg-4 col-sm-6 col-xs-12"},[_c('DetailsSelect',{attrs:{"field":{name: 'company_pricing_group_id', label: 'Pricing Group', options: _vm.companyPricingGroups},"val":_vm.data.company_pricing_group_id},on:{"save":_vm.website}})],1),_c('div',{staticClass:"col-lg-4 col-sm-6 col-xs-12"},[_c('DetailsSelect',{attrs:{"field":{name: 'primary_branch_id', label: 'Branches', options: _vm.companyBranches},"val":_vm.data.primary_branch_id},on:{"save":_vm.standard}})],1),_c('div',{staticClass:"col-lg-4 col-sm-6 col-xs-12"},[_c('UserCCs')],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }