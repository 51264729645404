<template>
    <div>
        <h2>Communications</h2>

        <div class="spacing_btm">
            <button class="btn btn-default" @click="dialog(typeCall)">Call</button>
            <button class="btn btn-default" @click="dialog(typeEmail)">Email</button>
            <button class="btn btn-default" @click="dialog(typeMeeting)">Meeting</button>
        </div>

        <div class="table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Type</th>
                        <th>Rep</th>
                        <th>Note</th>
                        <th>Done</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="task in data.crm_tasks" v-bind:key="task.crm_task_id">
                        <td>{{ task.date }}</td>
                        <td>{{ task.type }}</td>
                        <td>{{ task.assigned_to }}</td>
                        <td>{{ task.note }}</td>
                        <td>
                            <input type="checkbox" @change="updateCompletion(task.crm_task_id, task.completed)" :checked="task.completed" />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <Modal v-if="showDialog" :heading="`New ${this.type}`" @close="closeDialog">
            <template v-slot:body>
                <div class="form-group">
                    <div class="row">
                        <div class="col-xs-6">
                            <label>
                                Done
                                <input v-model.number="scheduleType" name="schedule" value=1 type="radio" />
                            </label>
                        </div>
                        <div class="col-xs-6">
                            <label>
                                Schedule
                                <input v-model.number="scheduleType" name="schedule" value=2 type="radio" />
                            </label>
                        </div>
                    </div>
                </div>

                <div v-show="scheduleType === 2">
                    <div class="form-group">
                        <label>Start</label>

                        <!-- TODO needs timepicker piece from this component's functionality -->
                        <DatePicker :pickTime="true" :format="'MM/DD/YYYY hh:mm'" :input-attributes="{ class: 'form-control'}" v-model="formFields.start.val"/>
                    </div>
                    <div class="form-group">
                        <label>End</label>

                        <!-- TODO needs timepicker piece from this component's functionality -->
                        <DatePicker :pick-time="true" v-model="formFields.end.val" :input-attributes="{ class: 'form-control' }" />
                    </div>
                    <div class="form-group">
                        <label>Assigned</label>

                        <Typeahead ref="assigned" v-bind:ID.sync="formFields.assigned.val" cdi_type="sales_employee" />
                    </div>
                    <div class="form-group">
                        <label>
                            Add to Google Calendar
                            <input v-model="formFields.addToCalendar.val" :true-value="1" :false-value="0" type="checkbox" />
                        </label>
                    </div>
                </div>

                <div class="form-group">
                    <label>Note</label>
                    <textarea v-model="formFields.note.val" class="form-control vertical-resize"></textarea>
                </div>
            </template>
            <template v-slot:footer>
                <button class="btn btn-primary" @click="add">Save</button>
            </template>
        </Modal>
    </div>
</template>

<script>
    import { store } from "@/store/BusinessObject.store";
    import Modal from "@/components/utils/Modal";
    import helpers from "@/store/helpers";
    import DatePicker from "@/components/utils/DatePicker";
    import Typeahead from "@/components/utils/typeaheads/Typeahead";

    export default {
        name: "CRM",
        components: {
            Typeahead,
            DatePicker,
            Modal
        },
        props: ["data"],
        data() {
            return {
                typeEmail: 1,
                typeCall: 2,
                typeMeeting: 3,
                showDialog: false,
                dialogType: null,
                showScheduleForm: false,
                scheduleType: 1, // Whether it's done or needs to be scheduled
                formFields: {
                    start: {val: null, qsField: '[start_dt]'},
                    end: {val: null, qsField: '[end_dt]' },
                    assigned: {val: null, qsField: '[assigned_to]'},
                    addToCalendar: {val: 0, qsField: '[calendar]'},
                    note: {val: '', qsField: '[note]'}
                },
            }
        },
        methods: {
            updateCompletion: function (taskID, completed) {
                let options = {
                    'params[crm_task_id]': taskID,
                    'params[completed]': Number(!completed)
                };

                store.apiWithObjectLoad('update_crm_task', options);
                this.resetData();
            },
            add: function () {
                let options = {
                    'params[completed]': this.scheduleType === 1 ? 1 : 0,
                    'params[type]': this.dialogType,
                    ...helpers.getQSPairs(this.formFields)
                };

                store.apiWithObjectLoad('new_crm_task', options);
                this.resetData();
            },
            dialog: function (type) {
                this.dialogType = type;
                this.showDialog = true;
            },
            resetData: function () {
                this.dialogType = null;
                this.scheduleType = 1;
                this.formFields.start.val = null;
                this.formFields.end.val = null;
                this.formFields.assigned.val = null;
                this.formFields.addToCalendar.val = 0;
                this.formFields.note.val = '';
                this.$refs.assigned.$data.object = null;
                this.showDialog = false;
            },
            closeDialog: function () {
                this.resetData();
            }
        },
        computed: {
            type: function () {
                switch (this.dialogType) {
                    case this.typeCall:
                        return 'Call';
                    case this.typeEmail:
                        return 'Email';
                    case this.typeMeeting:
                        return 'Meeting';
                    default:
                        return 'Unknown';
                }
            }
        }
    }
</script>

<style scoped>

</style>