<template>
    <div class="cdi_text_input object_detail" :title="field.label">
        <label :class="(hasPermission) ? 'cdi_edit' : ''" @click="toggleField">{{ field.label }} <span class="sub_label">{{field.subLabel}}</span>  <span v-if="field.tag" class="badge pull-right">{{ field.tag }}</span></label>
        <input :maxlength="field.charLimit" type="text" ref="detailInput" class="form-control" v-model="localVal" v-show="isActive" v-on:keyup.enter="toggleField" >
        <div v-if="!field.url" class="form-control" v-show="!isActive">{{ val }}</div>
        <div v-if="field.url" class="form-control" v-show="!isActive"><a target="_blank" :href="field.url">{{ val }}</a></div>
    </div>
</template>

<script>
export default {
    name: "DetailsText",
    props: ['field', 'val'],
    data() {
        return {
            isActive: false,
            localVal: ''
        }
    },
    methods: {
        toggleField: function() {
            if (this.hasPermission) {
                this.isActive = !this.isActive;
                if (this.isActive){
                    this.$nextTick(() => this.$refs.detailInput.select());
                }
                if (this.localVal != this.val){
                    this.save();
                }
                if (this.resetOp){
                    this.localVal = this.val;
                }
            }
        },
        save: function() {
            this.$emit('save', {'field': this.field.name, 'val': this.localVal});
            this.isActive = false;
        }
    },
    watch: {
        val: {
            immediate: true,
            handler: function () {
                this.localVal = this.val;
            }
        }
    },
    computed: {
        hasPermission: function () {
            if (this.field.permission === undefined) {
                return true;
            } else {
                return this.field.permission;
            }
        },
        resetOp: function() {
            return this.field.reset === true;
        }
    }
}
</script>

<style scoped>

</style>