import { cdiVars } from "@/cdiVars";

export default {
	ALL: [
		[cdiVars.CDI_ROLE_SUPER, 'Super'],
		[cdiVars.CDI_ROLE_HR, 'Human Resources'],
		[cdiVars.CDI_ROLE_SALES, 'Regional Sales'],
		[cdiVars.CDI_ROLE_ACCOUNTING, 'Accounting'],
		[cdiVars.CDI_ROLE_ENG, 'Engineering'],
		[cdiVars.CDI_ROLE_QC, 'Quality Control'],
		[cdiVars.CDI_ROLE_SHOP, 'Shop'],
		[cdiVars.CDI_ROLE_SHOP_SUP, 'Shop Supervisor'],
		[cdiVars.CDI_ROLE_LOGISTICS, 'Logistics'],
		[cdiVars.CDI_ROLE_MANAGER, 'Manager'],
		[cdiVars.CDI_ROLE_MATERIAL, 'Material'],
		[cdiVars.CDI_ROLE_LEADERSHIP, 'Leadership'],
		[cdiVars.CDI_ROLE_INSIDE_SALES, 'Sales Support'],
		[cdiVars.CDI_ROLE_BATCHING, 'Batching']
	]
}