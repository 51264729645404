<template>
    <div class="form_control_checkbox">
        <label>
            <input type='checkbox' :disabled="!hasPermission || field.disabled == 1" :true-value='1' :false-value="0" v-model="localVal" @change="save"> {{ field.label }}
        </label>
        <span v-if='field.display'>{{ field.display }}</span>
    </div>

</template>

<script>
export default {
    name: "DetailsCheckbox",
    props: ['field', 'val'],
    data() {
        return {
            localVal: '',
        }
    },
    methods: {
        save: function() {
            this.$emit('save', {'field': this.field.name, 'val': this.localVal});
        },
    },
    watch: {
        val: {
            immediate: true,
            handler() {
                this.localVal = this.val;
            }
        }
    },
    computed: {
        hasPermission: function () {
            if (this.field.permission === undefined) {
                return true;
            } else {
                return this.field.permission;
            }
        }
    }
}
</script>

<style scoped>

</style>