<template>
    <div class="cdi_button panel-body">
        <label>User CCs</label>
        <div class="form-inline">
            <span v-for="(cc, index) in state.object.data.user_ccs" v-bind:key="cc.user_id">
                <span v-if="cc.user_id != state.object.data.distributor_id">
                    <span @click="$refs.deleteConfirm.open(cc.user_id)" class="glyphicon glyphicon-trash cdi_link"></span>
                        {{ cc.first_name }} {{cc.last_name }}<span v-if="index != state.object.data.user_ccs.length - 2">, </span>
                </span>
            </span>
            &nbsp;
            <span class="controlled_width">
                <Typeahead v-show="showInput" :clearOnSelect="true"  cdi_type="search_my_users" :params="addParams" v-bind:ID.sync="ccUserId" />
            </span>
            &nbsp;
            <button @click="toggleUserCC" class="btn btn-default">
                <span v-show="showInput" class="glyphicon glyphicon-remove"></span>
                <span v-show="!showInput">Add CC</span>
            </button>
        </div>
        <Confirm ref="deleteConfirm" heading="Remove CC?" :body="`This person will no longer receive emails about this ${state.title}.`" @response="deleteCC" />
    </div>
</template>

<script>
import { store } from '@/store/BusinessObject.store';
import Typeahead from '@/components/utils/typeaheads/Typeahead';
import Confirm from '@/components/utils/Confirm';

export default {
    name: 'UserCcs',
    components: {Confirm, Typeahead},
    data() {
        return {
            state: store.state,
            showInput: false,
            ccUserId: 0,
        }
    },
    methods: {
        toggleUserCC: function() {
            this.showInput = !this.showInput;
        },
        deleteCC: function(userID) {
            if (confirm) {
                store.apiWithObjectLoad('remove_user_cc', { 'params[user_id]': userID });
            }
        },
        addCC: function(userID) {
            store.apiWithObjectLoad('add_user_cc', { 'params[user_id]': userID });
            this.showInput = false;
        }
    },
    computed: {
        addParams: function() {
            let params = {};
            if (!this.$appStore.isStaff()){
                params.company_id = this.state.object.data.company_id;
            }
            params.active_only = true;
            
            return params;
        }
    },
    watch: {
        ccUserId: function() {
            if (this.ccUserId) {
                this.addCC(this.ccUserId.replace("/user/",""));
                this.ccUserId = 0;
            }
        }
    }
}

</script>

<style scoped>
    .controlled_width {
        width: 100px;
    }
</style>