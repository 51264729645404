import userCache from "@/cache/user.cache";
import brandCache from "@/cache/brand.cache";
import stateCache from "@/cache/state.cache";
import cdiRoleCache from "@/cache/cdiRole.cache";

export const userStore = {
	state: {
		name: 'user',
		title: 'Person',
		titlePlural: 'People',
		nav: {
			base: 'user',
			newView: true,
			views: [
				{ view: 'dashboard', label: 'Dashboard' },
				{ view: 'details', label: 'Details' },
				{ view: 'history', label: 'History' }
			]
		},
		search: {
			sortFields: true,
			endPoint: 'users',
			formFields: {
				email: {
					val: '',
					qsField: '[strings][email]',
					label: 'Email',
					component: 'SearchTextInput'
				},
				firstName: {
					val: '',
					qsField: '[strings][first_name]',
					label: 'First name',
					component: 'SearchTextInput'
				},
				lastName: {
					val: '',
					qsField: '[strings][last_name]',
					label: 'Last Name',
					component: 'SearchTextInput'
				},
				userTypeID: {
					val: 0,
					qsField: '[numbers][user_type_id]',
					label: 'Record Type',
					component: 'SearchSelectInput',
					options: userCache.USER_TYPES
				},
				company: {
					val: '',
					qsField: '[numbers][u.company_id]',
					label: 'Company',
					cdiType: 'company',
					component: 'SearchTypeaheadInput'
				},
				city: {
					val: '',
					qsField: '[strings][city]',
					label: 'City',
					component: 'SearchTextInput',
					toggleHide: true,
					defaultVal: '',
				},
				state: {
					val: 0,
					qsField: '[strings][state]',
					label: 'State',
					component: 'SearchSelectInput',
					options: stateCache.STATES,
					toggleHide: true,
					defaultVal: 0,
				},
				zipCode: {
					val: '',
					qsField: '[strings][zip]',
					label: 'Zip',
					component: 'SearchTextInput',
					toggleHide: true,
					defaultVal: '',
				},
				phone: {
					val: '',
					qsField: '[strings][phone]',
					label: 'Phone',
					component: 'SearchTextInput',
					toggleHide: true,
					defaultVal: '',
				},
				regionalSalesRep: {
					val: '',
					qsField: '[numbers][sales_user_id]',
					label: 'Regional Sales Rep',
					cdiType: 'sales_employee',
					component: 'SearchTypeaheadInput',
					toggleHide: true,
					defaultVal: '',
				},
				salesSupportRep: {
					val: '',
					qsField: '[numbers][inside_sales_user_id]',
					label: 'Sales Support Rep',
					cdiType: 'sales_employee',
					component: 'SearchTypeaheadInput',
					toggleHide: true,
					defaultVal: '',
				},
				nationalSalesTeam: {
					val: '',
					qsField: '[numbers][national_sales_team_id]',
					label: 'National Sales Team',
					cdiType: 'sales_employee',
					component: 'SearchSelectInput',
					toggleHide: true,
					defaultVal: '',
				},
				specialTag: {
					val: 0,
					qsField: '[numbers][u.branch_id]',
					label: 'Special Tag',
					component: 'SearchSelectInput',
					options: userCache.USER_SPECIAL_TAGS,
					toggleHide: true,
					defaultVal: 0,
				},
				brands: {
					val: 0,
					qsField: '[numbers][brand_id]',
					label: 'Brands',
					component: 'SearchSelectInput',
					options: brandCache.TYPES,
					toggleHide: true,
					defaultVal: 0,
				},
				cdiRole: {
					val: 0,
					qsField: '[cdi_role_id]',
					label: 'CDI Role',
					component: 'SearchSelectInput',
					options: cdiRoleCache.ROLES,
					toggleHide: true,
					defaultVal: 0,
				},
				defaultCcId: {
					val: '',
					qsField: '[default_cc_id]',
					label: 'Default CC',
					cdiType: 'search_my_users',
					component: 'SearchObjectInput',
					toggleHide: true,
					params: {
						active_only: true,
					},
					defaultVal: '',
				},
				userID: {
					val: '',
					qsField: '[strings][u.user_id]',
					label: 'ID',
					component: 'SearchTextInput',
					toggleHide: true,
					defaultVal: '',
				},
				enabledOnly: {
					val: 1,
					qsField: '[enable_only]',
					label: 'Enabled Only',
					component: 'SearchCheckboxInput',
					toggleHide: true,
					defaultVal: 1,
				},
				hasGapps: {
					val: '',
					qsField: '[isset][has_gapps]',
					label: 'Has GApps',
					component: 'SearchCheckboxInput',
					toggleHide: true,
					defaultVal: '',
				},
				noTerms: {
					val: '',
					qsField: '[strings][terms_date]',
					label: 'No Terms',
					component: 'SearchCheckboxInput',
					trueValue: '0000-00-00',
					toggleHide: true,
					defaultVal: '',
				},
				pageSize: {val: 24, qsField: '[page_size]'},
				searchIndex: {val: 0, qsField: '[search_index]'},
				direction: {val: 'ASC', qsField: '[direction]'},
				orderBy: {
					val: 'email',
					qsField: '[orderby]',
					options: {
						'email': 'Email',
						'first_name': 'First Name',
						'last_name': 'Last Name',
						'company_name': 'Company',
						'branch_name': 'Branch'
					}
				}
			},
			resultFields: {
				user_id: {header: 'ID', link: 'user'},
				email: {header: 'Email', link: ''},
				first_name: {header: 'First', link: ''},
				last_name: {header: 'Last', link: ''},
				job_title: {header: 'Title', link: ''},
				company_name: {header: 'Company', link: ''},
				city: {header: 'City', link: ''}
			}
		},
		// Too custom to use our boilerplate stuff
		object: {}
	}
}