<template>
    <div>
      <div v-if="state.object.data.is_contractor">
        <p class="red-text">**CONTRACTOR**</p>
      </div>
        <div v-if="id">
            <BreadCrumbs :breadCrumbs="getBreadCrumbs" />
            <h1>{{ fullName }}</h1>

            <div v-show="showAlert" class="alert alert-info">
                <div v-show="state.object.data.duplicates && state.object.data.duplicates.length">
                    Please consider disabling potential duplicate(s):
                    <span v-for="duplicate in state.object.data.duplicates" v-bind:key="duplicate.user_id">
                        <router-link :to="`/user/${duplicate.user_id}`">
                            {{ duplicate.first_name }} {{ duplicate.last_name }}
                            ({{ duplicate.email }})
                        </router-link>
                    </span>
                </div>

                <div v-show="state.object.data.outside_sales_mismatch">
                    Regional Sales Rep mismatch (suggest {{ state.object.data.outside_sales_mismatch }})
                </div>

                <div v-show="state.object.data.inside_sales_mismatch">
                    Sales Support Rep mismatch (suggest {{ state.object.data.inside_sales_mismatch }})
                </div>
            </div>

            <Dashboard :data="state.object.data" v-show="isCurrentView('dashboard')" />
            <CRM :data="state.object.data" v-show="isCurrentView('dashboard')" />

            <div v-show="isCurrentView('details')">
                <Details :data="state.object.data" />
                <ContactDetails :data="state.object.data" />
                <OtherDetails :data="state.object.data" />
            </div>

            <div v-show="isCurrentView('cdi') && navHasEmployeeTab">
                <CDI :data="state.object.data" />
            </div>

            <div v-show="isCurrentView('history')">
                <div class="row">
                    <div class="col-md-3 col-sm-4 col-xs-6 form-group"><label>Login Count</label><div>{{ state.object.data.login_count }}</div></div>
                    <div class="col-md-3 col-sm-4 col-xs-6 form-group"><label>Last Login</label><div>{{ state.object.data.last_login }}</div></div>
                    <div class="col-md-3 col-sm-4 col-xs-6 form-group"><label>Last Quote</label><div>{{ state.object.data.last_quote_date }}</div></div>
                    <div class="col-md-3 col-sm-4 col-xs-6 form-group"><label>Last Order</label><div>{{ state.object.data.last_order_date }}</div></div>
                </div>

                <div class="row">
                    <div class="col-sm-6 col-xs-12">
                        <label>Last 5 Orders</label>
                        <ul>
                            <li v-for="tracker in state.object.data.past_orders" v-bind:key="tracker.tracker_id">
                                <router-link :to="`/tracker/${tracker.tracker_id}`">
                                    {{ tracker.tracker_id }}
                                </router-link>
                                ({{ tracker.tracker_date }})
                            </li>
                        </ul>
                    </div>
                    <div class="col-sm-6 col-xs-12">
                        <label>Last 5 Quotes</label>
                        <ul>
                            <li v-for="quote in state.object.data.past_quotes" v-bind:key="quote.quote_id">
                                <router-link :to="`/quote/${quote.quote_id}`">
                                    {{ quote.quote_id }}
                                </router-link>
                                ({{ quote.quote_date }})
                            </li>
                        </ul>
                    </div>
                </div>

                <History :history="state.object.data.history" />
            </div>
        </div>

        <Search v-else />
    </div>
</template>

<script>
    import { store } from "@/store/BusinessObject.store";
    import { userStore } from './User.store.js';
    import Search from "@/components/businessObjects/utils/Search";
    import BreadCrumbs from "@/components/businessObjects/utils/BreadCrumbs";
    import Details from "@/components/businessObjects/user/Details";
    import OtherDetails from "@/components/businessObjects/user/OtherDetails";
    import ContactDetails from "@/components/businessObjects/user/ContactDetails";
    import History from "@/components/businessObjects/utils/History";
    import Dashboard from "@/components/businessObjects/user/Dashboard";
    import CRM from "@/components/businessObjects/user/CRM";
    import CDI from "@/components/businessObjects/user/CDI";
    import { cdiVars } from "@/cdiVars";

    export default {
        name: "Container",
        props: ['id'],
        components: {
            CDI,
            CRM,
            Dashboard,
            History,
            ContactDetails,
            OtherDetails,
            Details,
            BreadCrumbs,
            Search
        },
        data() {
            return {
                state: store.state,
                userState: userStore.state
            }
        },
        created() {
            store.initialize(this.userState);
            this.load();
        },
        methods: {
            load: function () {
                if (!this.id) {
                    return;
                }

                store.load(this.id, 'dashboard')
                    .then(() => this.updateNav())
                    .then(() => this.$appStore.setTitle([this.fullName, this.state.titlePlural]));
            },
            isCurrentView: function (view) {
                return view === this.state.currentView;
            },
            updateNav: function () {
                if (this.isEmployee && this.state.object.data.cdi_access) {
                    if (this.navHasEmployeeTab) {
                        return;
                    }

                    // Insert CDI tab before the history tab
                    this.state.nav.views.splice(this.state.nav.views.length - 1, 0, {
                        view: 'cdi',
                        label: 'CDI'
                    });

                    return;
                }

                this.removeEmployeeTab();
            },
            removeEmployeeTab: function () {
                this.state.nav.views = this.state.nav.views.filter(function (row) {
                    return row.view !== 'cdi';
                });
            }
        },
        computed: {
            currentView: function () {
                return this.state.currentView;
            },
            fullName: function () {
                if (!this.state.object.data.first_name) {
                    return;
                }

                return `${this.state.object.data.first_name} ${this.state.object.data.last_name}`;
            },
            getBreadCrumbs: function () {
                let data = this.state.object.data;

                return [
                    {
                        id: data.company_id,
                        label: data.company_name,
                        slug: 'company'
                    },
                    {
                        id: data.primary_branch_id,
                        label: data.branch_name,
                        slug: 'branch'
                    },
                    {
                        id: data.user_id,
                        label: data.first_name + ' ' + data.last_name,
                        slug: 'user'
                    }
                ];
            },
            navHasEmployeeTab: function () {
                for (let i = 0; i < this.state.nav.views.length; i++) {
                    if (this.state.nav.views[i].view === 'cdi') {
                        return true;
                    }
                }

                return false;
            },
            isEmployee: function () {
                return this.state.object.data.user_type_id === cdiVars.USER_TYPE_EMPLOYEE;
            },
            isContact: function () {
                return this.state.object.data.user_type_id === cdiVars.USER_TYPE_CONTACT;
            },
            showAlert: function () {
                return (this.state.object.data.duplicates && this.state.object.data.duplicates.length)
                        || this.state.object.data.outside_sales_mismatch
                        || this.state.object.data.inside_sales_mismatch;
            }
        },
        watch: {
            id: function () {
                this.load();
            },
            currentView: function () {
                switch (this.currentView) {
                    case 'new':
                        this.$router.push({path: `/my/register`});
                        break;
                }
                if(this.currentView == 'search'){
                    window.location.reload()
                }
            }
        }
    }
</script>

<style scoped>
.red-text {
  color: red;
}
</style>