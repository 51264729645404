<template>
    <div>
        <div class="panel panel-default">
            <div class="row panel-body details_container">
                <div class="col-lg-4 col-sm-6 col-xs-12">
                    <DetailsStatic :field="{name: 'user_id', label: 'ID'}" :val="data.user_id" />
                </div>
                <div class="col-lg-4 col-sm-6 col-xs-12">
                    <DetailsText :field="{name: 'email', label: 'Email'}"
                                 :val="data.email"
                                 @save="standard"
                    />
                </div>
                <div class="col-lg-4 col-sm-6 col-xs-12" v-show="!isEmployee && !isContact">
                    <DetailsButton :field="{postValue: data.email, label: 'Password', buttonLabel: 'Send Password Reset', endPoint: 'myuser/reset_password_request'}"
                                   @post="post"
                    />
                </div>
                <div class="col-lg-4 col-sm-6 col-xs-12">
                    <DetailsSelect :field="{name: 'user_type_id', label: 'Record Type', options: cache.userCache.USER_TYPES}"
                                   :val="data.user_type_id"
                                   @save="standard"
                    />
                </div>
                <div class="col-lg-4 col-sm-6 col-xs-12">
                    <DetailsText :field="{name: 'first_name', label: 'First'}"
                                 :val="data.first_name"
                                 @save="standard"
                    />
                </div>
                <div class="col-lg-4 col-sm-6 col-xs-12">
                    <DetailsText :field="{name: 'last_name', label: 'Last'}"
                                 :val="data.last_name"
                                 @save="standard"
                    />
                </div>
                <div class="col-lg-4 col-sm-6 col-xs-12">
                    <DetailsTypeahead :field="{
                                            name: 'company_id',
                                            label: 'Company',
                                            display: this.data['company_name'],
                                            cdiType: 'company',
                                            link: 'company'
                                          }"
                                      :val="data.company_id"
                                      @save="standard"
                    />
                </div>
                <div class="col-lg-4 col-sm-6 col-xs-12" v-show="!isEmployee">
                    <DetailsText :field="{name: 'job_title', label: 'Title'}"
                                 :val="data.job_title"
                                 @save="sales"
                    />
                </div>
                <div class="col-lg-4 col-sm-6 col-xs-12">
                    <DetailsSelect :field="{name: 'assigned_cdi_location_id', label: 'Assigned CDI Location', options: cache.cdiLocationCache.CDI_LOCATIONS}"
                                   :val="data.assigned_cdi_location_id"
                                   @save="standard"
                    />
                </div>
                <div class="col-lg-4 col-sm-6 col-xs-12">
                    <DetailsStatic :field="{name: 'terms_date', label: 'Terms Date'}" :val="data.terms_date" />
                </div>
                <div class="col-lg-4 col-sm-6 col-xs-12">
                    <DetailsCheckbox :field="{name: 'disable', label: 'Disable'}"
                                     :val="data.disable"
                                     @save="standard"
                    />
                </div>
                <div class="col-xs-12" v-show="!isEmployee">
                    <DetailsTextarea :field="{name: 'sales_notes', label: 'Sales Notes'}"
                                     :val="data.sales_notes"
                                     @save="sales"
                    />
                </div>
            </div>
        </div>
      <div class="col-lg-12 col-sm-12 col-xs-12" v-if="questions && questions.length && data.is_contractor && !loadingQuestions">
        <h3>Contractor Questions</h3>
        <div v-if="questions && questions.length">
          <div v-for="(group, questionId) in groupedQuestions" :key="questionId">
            <h4 v-if="questionId == 1">Which Roof Top Unit brands do you use on change outs?</h4>
            <h4 v-else-if="questionId == 2">Roughly how many RTUs do you change out annually?</h4>
            <h4 v-else-if="questionId == 3">Are you aware that CDI has a measure service and would this be helpful to you?</h4>
            <ul>
              <li v-for="question in group" :key="question.contractor_questions_id">
                {{ getAnswerLabel(question.question_id, question.answer) }}
              </li>
            </ul>
          </div>
        </div>
        <div v-else>
          Loading questions...
        </div>
      </div>
        <div v-else>
          <p>No questions found.</p>
        </div>
        <h3>Permissions</h3>
        <div class="panel panel-default" v-if="data.authorization">
            <div class="row panel-body details_container">
                <div class="col-sm-12">
                    <p>User permissions do not go into effect until the user logs out and back in. We do not have the ability to revoke privileges after they have signed in.</p>
                </div>
                <div class="col-sm-6">
                    <DetailsCheckbox :field="{name: 'view_quote_pricing', label: 'Quote Pricing', display: 'User can see quote pricing.'}"
                                     :val="data.authorization.view_quote_pricing"
                                     @save="authorization"
                    />
                </div>
                <div class="col-sm-6">
                    <DetailsCheckbox :field="{name: 'view_order_pricing', label: 'Order Pricing', display: 'User can see order pricing.'}"
                                     :val="data.authorization.view_order_pricing"
                                     @save="authorization"
                    />
                </div>
                <div class="col-sm-6">
                    <DetailsCheckbox :field="{name: 'can_order', label: 'Can Order', display: 'User is allowed to place orders.'}"
                                     :val="data.authorization.can_order"
                                     @save="authorization"
                    />
                </div>
                <div class="col-sm-6">
                    <DetailsCheckbox :field="{name: 'see_branch_orders', label: 'View Branch Orders', display: 'User is able to see orders owned by users at their branch.'}"
                                     :val="data.authorization.see_branch_orders"
                                     @save="authorization"
                    />
                </div>
                <div class="col-sm-6">
                    <DetailsCheckbox :field="{name: 'see_company_orders', label: 'View Company Orders', display: 'User is able to see the orders owned by users at their company.'}"
                                     :val="data.authorization.see_company_orders"
                                     @save="authorization"
                    />
                </div>
              <div class="col-sm-6">
                <DetailsCheckbox :field="{name: 'credit_card_payment_only', label: 'Credit Card Payments Only', display: 'User is only able to pay by credit card.'}"
                                 :val="data.authorization.credit_card_payment_only"
                                 @save="authorization"
                />
              </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { store } from "@/store/BusinessObject.store";
    import userCache from "@/cache/user.cache";
    import cdiLocationCache from "@/cache/cdiLocation.cache";
    import DetailsStatic from "@/components/businessObjects/utils/DetailsStatic";
    import DetailsText from "@/components/businessObjects/utils/DetailsText";
    import DetailsButton from "@/components/businessObjects/utils/DetailsButton";
    import DetailsSelect from "@/components/businessObjects/utils/DetailsSelect";
    import DetailsTypeahead from "@/components/businessObjects/utils/DetailsTypeahead";
    import DetailsCheckbox from "@/components/businessObjects/utils/DetailsCheckbox";
    import DetailsTextarea from "@/components/businessObjects/utils/DetailsTextarea";
    import { cdiVars } from "@/cdiVars";
    import {myStore} from "@/store/My.store";
    import contractorQuestionsCache from "@/cache/contractorQuestions.cache";

    export default {
      name: 'Details',
      components: {
        DetailsTextarea,
        DetailsCheckbox,
        DetailsTypeahead,
        DetailsSelect,
        DetailsButton,
        DetailsText,
        DetailsStatic
      },
      props: ['data'],
      data() {
        return {
          questions: [],
          loadingQuestions: true,
          cache: {
            userCache,
            cdiLocationCache,
            contractorQuestionsCache
          }
        }
      },
      methods: {
        standard: function (data) {
          store.save(data.field, data.val);
        },
        post: function (data) {
          store.customApi(data.endPoint, {'params': data.postValue});
        },
        authorization: function (data) {
          let scope = this
          let field = `params[${data.field}]`;
          let options = {[field]: data.val};
          store.customApi(`user/update_authorization/${scope.data.user_id}`, options);
        },
        sales: function (data) {
          let field = `params[${data.field}]`;
          let options = {[field]: data.val};
          store.apiWithObjectLoad('save_user_sales', options);
        },
        getContractorQuestions: function () {
          let scope = this
          let field = `params[user_id]`;
          let options = {[field]: scope.data.user_id};
          myStore.api(`myuser/get_contractor_questions`, options).then(data => {
            if (data && data.length) {
              scope.questions = data;
              this.loadingQuestions = false;
            }
          });
        },
        getAnswerLabel(questionId, answerId) {
          const questionKey = `Q${questionId}`;
          const questionArray = contractorQuestionsCache[questionKey];
          if (questionArray) {
            const found = questionArray.find(([id]) => id === answerId);
            return found ? found[1] : 'Unknown';
          }
          return 'Question ID does not exist';
        }
      },
      computed: {
        isEmployee: function () {
          return this.data.user_type_id === cdiVars.USER_TYPE_EMPLOYEE;
        },
        isContact: function () {
          return this.data.user_type_id === cdiVars.USER_TYPE_CONTACT;
        },
        groupedQuestions() {
          return this.questions.reduce((acc, question) => {
            if (!acc[question.question_id]) {
              acc[question.question_id] = [];
            }
            acc[question.question_id].push(question);
            return acc;
          }, {});
        },
      },
      mounted() {
        setTimeout(() => {
          this.getContractorQuestions();
        }, 3000);

      }
    }
</script>

<style scoped>

</style>