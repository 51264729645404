export default {
    Q1: [
        [14, "TRANE"],
        [7, "LENNOX"],
        [2, "CARRIER"],
        [16, "YORK"],
        [1, "BRYANT"],
        [4, "GOODMAN"],
        [67, "DAIKIN"],
        [9, "RHEEM"],
        [0, "OTHER"],
    ],

    Q2: [
        [1, "Less than 50"],
        [2, "50 - 200"],
        [3, "201 - 500"],
        [4, "More than 500"],
    ],

    Q3: [
        [1, "Yes"],
        [2, "No"],

    ]
}