<template>
    <div class="cdi_text_input object_detail" :title="field.title">
        <label><span :class="(hasPermission) ? 'cdi_edit' : ''" @click="toggleField"> {{ field.label }}</span> <span v-show="field.canClear" :class="(hasPermission) ? 'cdi_edit pull-right' : ''" @click="clear" >clear</span></label>
        <Typeahead :cdi_type="field.cdiType" v-show="isActive" :params='field.params'  @objectSelected="save" />
        <div class="form-control" style="overflow: auto;" v-show="!isActive">
            <span v-if="field.link"><router-link :to="`/${field.link}/${val}`">{{ field.display }}</router-link></span>
            <span v-else style="white-space: pre-wrap">{{ field.display }}</span>
        </div>
    </div>
</template>

<script>
import Typeahead from '@/components/utils/typeaheads/Typeahead';
export default {
    name: "DetailsTypeahead",
    props: {
        field: {},
        val: {},
        params: {
            default: ''
        }
    },
    components: {
        Typeahead,
    },
    data() {
        return {
            isActive: false,
        }
    },
    methods: {
        toggleField: function() {
            if (this.hasPermission) {
                this.isActive = !this.isActive;
            }
        },
        save: function(object) {
            this.$emit('save', {'field': this.field.name, 'val': (object.id) ? object.id : object.value});
            this.isActive = false;
        },
        clear: function (){
            this.$emit('save', {'field': this.field.name, 'val': 0});
            this.isActive = false;
        }
    },
    computed: {
        hasPermission: function () {
            if (this.field.permission === undefined) {
                return true;
            } else {
                return this.field.permission;
            }
        }
    }
}
</script>

<style scoped>

</style>